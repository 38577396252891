import React, { useEffect, useState } from 'react'

function Footer() {

	const [title, setTitle] = useState("");
	useEffect(()=>{
		function randomElement(items) {
		console.log("it's happening")
			return items[Math.floor(Math.random()*items.length)];
		}

		setTitle(randomElement(quotes))
	}, [])
	
	return(
		<div className="site-footer">
			{title}
		</div>
  );
}

export default Footer

let quotes = [
	`"Art is anything made special." - Ellen Dissanayake`,
	`"I might not change the world, but I will spark the mind of someone who will." - Tupac Shakur`,
	`"In theory, theory and practice are the same. In practice, they are not. - Benjamin Brewster`,
	`"Life is the exercise of developing a perspective of the world."`,
	`"Let’s talk about it, cause when you got a story as real as mine, you can’t leave nothing out of it. - Nas`,
	`"Write how you talk on your best day" - Tracy Kidder`,
	`"Every story has to be discovered twice. First in the world, then in the author’s study. - Tracy Kidder`,
	`"I’m interested in things that could only have come from my head, that grow out of some strange interest that I have a history with." - Omar Rizwan`,
	`"The most important decision we make is whether we believe we live in a friendly or hostile universe." - Albert Einstein`,
	`"I don’t need to wait for the perfect time. It’s always the right time to make things happen." - Kaya Thomas`,
	`"You can’t use up creativity. The more you use, the more you have." - Maya Angelou`,
	`"Be curious, not judgmental." - Walt Whitman`,
	`"The most exciting phrase to hear in science, the one that heralds new discoveries, is not “Eureka” but “That’s funny...” - Isaac Asmimov"`,
	`"Society grows great when old men plant trees whose shade they know they shall never sit in."`,
	`"From what we get, we can make a living; what we give, however, makes a life." - Arthur Ashe`,
	`"Comparison is the death of happiness." - Mark Twain`,
	`"Discovering the truth about ourselves is a lifetime's work, but it's worth the effort." - Fred Rogers`,
	`"Life's most persistent and urgent question is “what are you doing for others?” - Martin Luther King Jr.`,
	`"Be regular and orderly in your life, so that you may be violent and original in your work." - Gustave Flaubert`,
	`"You won’t know the end until it ends, so let’s fill the middle up with as many plot twists as possible." - Cabel Sasser`,
	`"I am an optimist. It does not seem too much use being anything else." - Winston Churchill`,
	`"Without changing our pattern of thought, we will not be able to solve the problems we created with our current patterns of thought." - Albert Einstein`,
	`"A change in perspective is worth 80 IQ points." - Alan Kay`,
	`"Use your intuition to ask questions, not to answer them." - John Ousterhout`,
	`"Let the hindsight of your future self become the foresight of your present self." - Shane Parrish`,
	`"Only a fool learns from his own mistakes. The wise man learns from the mistakes of others. Fools learn from experience." - Otto Von Bismarck`,
	`"We are what we repeatedly do." - Aristotle`,
	`"The fool doth think he is wise, but the wise man knows himself to be a fool." - William Shakespeare`,
	`"We learn in order not to learn." - Ivan Illich`,
	`"If you want to go quickly, go alone. If you want to go far, go together"`,
	`"The test of a first-rate intelligence is the ability to hold two opposed ideas in mind at the same time and still retain the ability to function." - F. Scott Fitzgerald`,
	`"If you can't explain it simply, you don't understand it well enough."`,
	`"The perfect is the enemy of the good." - Voltaire`,
	`"He is careful of what he reads, for that is what he will write. He is careful of what he learns, for that is what he will know." - Annie Dillard`,
]