import React from 'react'
import { Link } from 'gatsby'

function Header({siteMetadata}) {
	return(
		<div className="site-header">
			<div className="left-box">
	  			<div className="site-title">
					<Link className="site-title" to="/">Build.ms</Link>
				</div>

				<div className="about-icons">
					<Link to={`https://twitter.com/${siteMetadata.social.twitter}`}>
						<i className="fa fa-twitter" aria-hidden="true" />
					</Link>

					<Link to={`https://github.com/${siteMetadata.social.github}`}>
						<i className="fa fa-github" aria-hidden="true" />
					</Link>

			     	<Link to="https://redpanda.club">
						<i className="fa fa-code" aria-hidden="true" />
					</Link>

					<Link to="mailto:joe@redpanda.club">
						<i className="fa fa-envelope-o" aria-hidden="true" />
					</Link>
					
					<Link to={`${siteMetadata.social.rss}`}>
						<i className="fa fa-rss" aria-hidden="true" />
					</Link>
				</div>
			</div>

			<div className="site-header-mergesort-box">
				<div className="site-tagline">
					The ms stands for <Link to="/about">mergesort</Link>
				</div>

				<Link to="/about">
					<picture>
						<source srcset="/images/mergesort-winking-light-blue.png" media="(prefers-color-scheme: dark)" />
						<source srcset="/images/mergesort-winking-dark-blue.png" media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
						<img className="logo" src="/images/mergesort-winking-light-blue.png" />
					</picture>
				 </Link>
			</div>
  		</div>
  );
}

export default Header