import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Header from '.././components/header'
import Footer from '.././components/footer'
import { StaticQuery, graphql } from 'gatsby'

import '../styles/main.scss'
import '../styles/fonts/font-awesome/css/font-awesome.min.css'

function DefaultLayout({children}) {
  return(  
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              author
              description
              social {
                twitter
                github
                email
                rss
              }
            }
          }
        }
      `}
      render={data => (
        <div>
        <Header siteMetadata={data.site.siteMetadata} />
        
        <div className="wrapper">  
          {children}
          <Footer />
		  <a rel="me" href="https://macaw.social/@mergesort"></a>
		  <a rel="me" href="https://indieapps.space/@redpandaclub"></a>
		  <a rel="me" href="https://social.lol/@mergesort"></a>
        </div>
        </div>
        
      )}
  />
);
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
